import React from "react"

const Heading = ({ title }) => (
  <div className="row">
    <div className="col text-center">
      <h2>{title}</h2>
    </div>
  </div>
)

export default Heading
